.page-layout.period-matrix-details .page-content {
  display: flex;
  flex-direction: column;
  gap: 1.8571rem;
}

.page-layout.period-matrix-details .page-content .period-matrix-data .card-header-container .header {
  display: flex;
  justify-content: space-between;
}

.page-layout.period-matrix-details .page-content .period-matrix-data {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.page-layout.period-matrix-details .page-content .period-matrix-data .card-header-container .sub-header-period-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-layout.period-matrix-details .page-content .text-input.search-box {
  width: 19.7857rem;
}

.page-layout.period-matrix-details .table-container .employee-info {
  display: flex;
  flex-direction: column;
  gap: 0.0714rem;
}

.page-layout.period-matrix-details .employee-info .employee-id {
  color: var(--grey-palette-01);
}

.page-layout.period-matrix-details .evaluation-summary {
  display: flex;
  align-items: center;
  gap: .5714rem;
}

.page-layout.period-matrix-details .evaluation-summary .extra-score {
  font-weight: 600;
}

@media only screen and (max-width: 425px) {
  .page-layout.period-matrix-details .page-content .text-input.search-box {
    width: 100%;
  }
}



