.evaluation-matrix {
    gap: .5714rem;
}

.evaluation-matrix .page-content {
    display: flex;
    flex-direction: column;
    gap: 1.4286rem;
}

.evaluation-matrix .page-content .current-matrix-card {
    display: flex;
    flex-direction: column;
    gap: .2857rem;
}

.evaluation-matrix .page-content .evaluation-matrix-history {
    display: flex;
    flex-direction: column;
    gap: .5714rem;
}
