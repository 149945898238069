.skills-page .page-content {
  display: flex;
  flex-direction: column;
  gap: 0.8571rem;
}

.skills-page .page-content .skills-category-data {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.skills-page .page-content .skills-category-data .header {
  display: flex;
  justify-content: space-between;
}

.skills-page .page-content .skills-category-data .info-block .info-block-icon {
  width: 1.2857rem;
  height: 1.2857rem;
}

.skills-page
  .page-content
  .skills-category-data
  .info-block
  .info-block-icon
  svg {
  width: 100%;
  height: auto;
}

.popup-container.system-popup.warning-popup.disable-skill-popup
  .popup-buttons
  .button.primary {
  background: var(--status-red);
}
