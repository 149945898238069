.status-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 19.7143rem; */
  flex: 1;
  height: 10rem;
  padding: 2.2143rem 0.7143rem 1.785rem 0.7143rem;
  gap: 0.2857rem;
  border-radius: 0.7143rem;
  background-color: var(--secondary-color);
  color: #ffffff;
}
.status-card-container.selectable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.status-card-container .status-card-value {
  font-weight: var(--font-weight-bold);
  font-size: 3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

.status-card-container .status-card-text {
  font-weight: var(--font-weight-medium);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  text-wrap: wrap;
}

.status-card-container.status-yellow {
  background-color: var(--status-yellow-transparency-3);
}

.status-card-container.status-red {
  background-color: var(--status-red-transparency-3);
}

.status-card-container.status-blue {
  background-color: var(--status-blue-transparency-3);
}

.status-card-container.status-lime-green {
  background-color: var(--status-lime-green);
}

.status-card-container.status-light-grey {
  background-color: var(--status-grey);
  color: #ffffff;
}

.status-card-container.status-dark-grey {
  background-color: var(--secondary-color);
  color: #ffffff;
}

.status-card-container.status-green {
  background-color: var(--status-green);
  color: #ffffff;
}

.status-card-container.status-yellow,
.status-card-container.status-red,
.status-card-container.status-blue,
.status-card-container.status-lime-green {
  color: var(--secondary-color);
}

@media only screen and (max-width: 1024px) {
  .status-card-container {
    height: 4.2857rem;
    flex-direction: row;
    padding: 0 1.1429rem;
    gap: 1rem;
  }

  .status-card-container .status-card-value, .status-card-container .status-card-text {
    text-align: left;
    width: unset;
  }

  .status-card-container .status-card-value {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .status-card-container {
    height: 3.2857rem;
    gap: 0.4286rem;
    padding: 0 0.8571rem;
  }

  .status-card-container .status-card-value {
    font-size: 1.4286rem;
  }
}