.text-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .2857rem;
    font-weight: var(--font-weight-medium);
}

.text-button .text-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.1429rem;
    min-width: 1.1429rem;
    height: 1.1429rem;
    min-height: 1.1429rem;
}

.text-button .text-button-icon svg {
    width: 100%;
    height: auto;
}

.text-button.primary {
    color: var(--primary-color);
}

.text-button.primary:hover {
    color: var(--secondary-color);
}

.text-button.primary .text-button-icon svg path {
    fill: var(--primary-color);
}

.text-button.primary:hover .text-button-icon svg path {
    fill: var(--secondary-color);
}

.text-button.secondary {
    color: var(--secondary-color);
}

.text-button.secondary:hover {
    color: var(--primary-color);
}

.text-button.secondary .text-button-icon svg path {
    fill: var(--secondary-color);
}

.text-button.secondary:hover .text-button-icon svg path {
    fill: var(--primary-color);
}

.text-button.white {
    color: white;
}

.text-button.white:hover {
    color: var(--primary-color);
}

.text-button.white .text-button-icon svg path {
    fill: white;
}

.text-button.white:hover .text-button-icon svg path {
    fill: var(--primary-color);
}

.text-button.disabled {
    opacity: 0.3;
    pointer-events: none;
}
