.popup-select-roles .add-role-btn {
    align-self: flex-end;
    height: 2.8571rem;
    max-height: 2.8571rem;
    width: 2.8571rem;
    max-width: 2.8571rem;
}

.popup-select-roles .table-container table thead {
    height: 1.8571rem;
}

.popup-select-roles thead .table-column-header {
    font-size: .8571rem;
    color: var(--grey-palette-01);
}

.popup-select-roles .table-cell {
    padding: .4286rem .8571rem;
    min-height: 2.5714rem; 
}