.follow-up-card {
    display: flex;
    flex-direction: column;
}

.follow-up-card .card-header {
    display: flex;
    justify-content: space-between;
}

.follow-up-card .add-follow-up-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.popup-container.system-popup.warning-popup.delete-follow-up-item-popup .popup-buttons .button.primary {
    background: var(--status-red);
}