.view-business-lines-page .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}


.view-business-lines-page .page-content .table-row .toggle-div .toggle-div-overlay{
    cursor: default; 

}




