.employee-bonus {
    gap: .5714rem;
}

.employee-bonus .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}

.employee-bonus .page-content .text-input.search-box {
    width: 19.7857rem;
}

.employee-bonus .page-content .mobile-table-row .status-cell .tag {
    width: 100%;
    border-radius: 0.7143rem 0.7143rem 0 0;
}

@media only screen and (max-width: 425px) {
    .employee-bonus .page-content .text-input.search-box {
        width: 100%;
    }
  }
