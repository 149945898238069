.employee-employee-career-ladder {
  gap: 2.8571rem;
}

.employee-career-ladder .page-content {
  display: flex;
  flex-direction: column;
  gap: 2.1429rem;
}

.employee-career-ladder .page-content .add-employee-career-ladder-role {
  width: 2.1429rem;
  min-width: 2.1429rem;
  height: 2.1429rem;
  min-height: 2.1429rem;
}

.employee-career-ladder .page-content .add-employee-career-ladder-role svg {
  width: 1.1429rem;
  height: auto;
}

.employee-career-ladder
  .page-content
  .add-employee-career-ladder-role
  svg
  path {
  fill: #ffffff;
}
