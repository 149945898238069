.my-certifications-page .page-content {
  display: flex;
  flex-direction: column;
  gap: 0.8571rem;
}

.my-certifications-page .text-input.search-box {
  width: 19.7857rem;
}

.my-certifications-page table tbody svg path:last-child {
  fill: var(--secondary-color);
}

.my-certifications-page .mobile-table-row svg path:last-child {
  fill: var(--secondary-color);
}

@media only screen and (max-width: 425px) {
  .my-certifications-page .text-input.search-box {
    width: 100%;
  }
}
