.popup-edit-employee-certification .separator {
    font-weight: var(--font-weight-bold);
    color: var(--grey-palette-01);
    border-bottom: 1px solid rgba(185, 184, 184, 0.2);
    padding-bottom: 0.125rem;
    margin-top: 0.2857rem;
  }


.popup-edit-employee-certification .attachment-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

 