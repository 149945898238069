.card-container.titled {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.card-container.titled .header-container {
  display: flex;
  justify-content: space-between;
}

.card-container.titled .header-container .title-left {
  font-size: 1.2857rem;
  font-weight: var(--font-weight-medium);
}

.card-container.titled .header-container .title-right {
  display: flex;
  align-items: center;
  gap: 1.1429rem;
}
