
.skills-categories-page .page-layout-header-action-buttons .header-btns {
    display: flex;
    gap: 1rem; 
}


.skills-categories-page .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}

.popup-container.system-popup.warning-popup.disable-skills-category-popup .popup-buttons .button.primary {
    background: var(--status-red);
}