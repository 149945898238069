:root {
  --side-bar-width: 5.7143rem;
}

.side-bar {
  height: 100%;
  min-width: var(--side-bar-width);
  max-width: var(--side-bar-width);
  background-color: var(--grey-palette-02);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.1429rem 0;
  gap: 2.8571rem;
}
