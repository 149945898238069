.criterias {
    flex: 1;
}

.criterias .criterias-header {
    display: flex;
    justify-content: space-between;
}

.popup-container.system-popup.warning-popup.delete-criteria-popup .popup-buttons .button.primary {
    background: var(--status-red);
}