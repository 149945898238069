.select-single {
  --select-single-padding: 0.571rem;
  display: flex;
  align-items: center;
  --select-single-height: 2.857rem;
  height: var(--select-single-height);
  /*     min-width: 14.286rem;  */
  border: 1px solid var(--border-main-color);
  padding: 0 var(--select-single-padding);
  pointer-events: auto;
  position: relative;
  border-radius: 0.3571rem;
}

.select-single.alternative-style {
  border-color: #ffffff;
  background-color: #ffffff;
}

.select-single .arrow-down-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.2857rem;
  height: auto;
}

.select-single .arrow-down-icon path {
  fill: var(--secondary-color);
}

.select-single:hover,
.select-single:focus-within {
  border-color: var(--primary-color);
}

.select-single.error,
.select-single.error:hover {
  border-color: var(--error-color);
}

.select-single.disabled,
.select-single.disabled:hover {
  border-color: var(--grey-palette-02);
  background-color: var(--grey-palette-03);
}

.select-single .native-input {
  flex-grow: 1;
  padding: 0;
  padding-left: 0.286rem;
  min-width: 2.8571rem;
  caret-color: var(--secondary-color);
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.select-single.has-icon .native-input {
  padding-left: 0.429rem;
}

.select-single .native-input:focus {
  outline: none;
}

.select-single .native-input::placeholder {
  color: var(--placeholder-color);
}

.select-single.disabled .native-input::placeholder {
  color: var(--placeholder-disabled-color);
}

.select-single .select-single-icon {
  display: flex;
  align-items: center;
}

.select-single .arrow-down-icon path {
  fill: black;
}

.select-single:hover .select-single-icon svg,
.select-single:focus-within .select-single-icon svg {
  fill: var(--primary-color);
}

.select-single.disabled,
.select-single.disabled:hover {
  background-color: var(--grey-palette-03);
}

.select-single.disabled .arrow-down-icon path,
.select-single.disabled:hover .arrow-down-icon path {
  fill: var(--placeholder-disabled-color);
}

.select-single.disabled:hover .arrow-down-icon {
  cursor: unset;
}

.select-single.error .select-single-icon svg,
.select-single.error:hover .select-single-icon svg,
.select-single.error:focus-within .select-single-icon svg {
  fill: var(--error-color);
}

.select-single-options-panel {
  position: absolute;
  top: var(--select-single-height);
  left: 0;
  /* min-width: 100%; */
  z-index: 90;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: #ffffff;
  color: var(--text-main-color);
  max-height: 11.6429rem;
  padding: 0.8571rem 0 0.4286rem 0;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0.3571rem;
}

.select-single-options-panel .single-select-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding: 0 0.857rem 0.1429rem 0.857rem;
}

.select-single-options-panel
  .single-select-actions
  .mini-text-button {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 0.857rem;
  font-weight: var(--font-weight-bold);
}

.select-single-options-panel
  .single-select-actions
  .mini-text-button:hover {
  color: var(--secondary-color);
}

.select-single-options-panel .single-select-option {
/*   min-height: 2.1429rem; */
  padding: 0.357rem 0.857rem;
}

.select-single-options-panel .single-select-option.hover,
.select-single-options-panel .single-select-option:hover {
  background-color: var(--grey-palette-02);
  cursor: pointer;
}

.select-single-options-panel .single-select-option.selected {
  display: flex;
  justify-content: space-between;
  color: var(--primary-color);
  font-weight: var(--font-weight-bold);
  background-color: var(--grey-palette-02);
}

.select-single-options-panel
  .single-select-option.selected
  .check-option
  path {
  fill: var(--primary-color);
}

.select-single-options-panel
  .single-select-option.selected
  .check-option
  path:first-child {
  fill: none;
}
