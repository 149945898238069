.evaluation-details
  .page-layout-header
  .page-layout-header-action-buttons
  .primary
  svg
  path:first-child {
  fill: none;
}

.evaluation-details
  .page-layout-header
  .page-layout-header-action-buttons
  .reject-btn {
  background: var(--status-red);
}

.evaluation-details .page-content {
  display: flex;
  flex-direction: column;
  gap: 1.4286rem;
}

.evaluation-details .evaluation-details-summary {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1.4286rem;
}

.evaluation-details .evaluation-details-summary .employee-data {
  height: auto;
}

.evaluation-details
  .evaluation-details-summary
  .evaluation-details-summary-cards {
  display: grid;
  gap: 1.4286rem;
  grid-template-columns: repeat(2, 1fr);
}

.evaluation-details
  .evaluation-details-summary
  .evaluation-details-summary-cards-updated {
  display: grid;
  gap: 1.4286rem;
  grid-template-columns: repeat(3, 1fr);
}

.evaluation-details .skills-categories {
  display: flex;
  flex-direction: column;
  gap: 1.4286rem;
}

.evaluation-details .additional-info {
  display: flex;
  flex-direction: row;
  gap: 1.4286rem;
}

.evaluation-details .additional-info .absences-status-card {
  max-width: 14.2857rem;
  height: auto;
  justify-content: center;
}

.evaluation-details .feedback-cards {
  display: flex;
  gap: 1.4286rem;
}

.evaluation-details .feedback-cards .card-container {
  flex: 1;
}

@media only screen and (max-width: 1200px) {
  .evaluation-details .additional-info {
    flex-direction: column;
    gap: 1.4286rem;
  }

  .evaluation-details .additional-info .absences-status-card {
    max-width: 100%;
    min-height: 3.5714rem;
  }
}

@media only screen and (max-width: 1024px) {
  .evaluation-details .evaluation-details-summary {
    grid-template-columns: 1fr;
    gap: 0.8571rem;
  }

  .evaluation-details
    .evaluation-details-summary
    .evaluation-details-summary-cards,
  .evaluation-details
    .evaluation-details-summary
    .evaluation-details-summary-cards-updated {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .evaluation-details .feedback-cards {
    flex-direction: column;
  }
}
