.bonus-evaluation-card {
  height: auto;
  flex: 1;
}

.card-container.titled.bonus-evaluation-card {
  gap: 0.4286rem;
}

.bonus-evaluation-card table thead {
  height: 1.8571rem;
}

.bonus-evaluation-card table thead .table-column-header {
  font-size: 0.8571rem;
  color: var(--grey-palette-01);
}

.bonus-evaluation-card table tbody .table-cell {
  min-height: 2.5714rem;
  padding: 0.7143rem 0.857rem;
}
