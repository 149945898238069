.popup-container.popup-evaluation-history {
    min-width: 50vw;
}

.popup-container.popup-evaluation-history .popup-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    min-height: 20vh;
    /* max-height: 30vh; */
}
