.business-lines-content-container {
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: calc(100% - var(--side-bar-width));
}

@media only screen and (max-width: 425px) {
  .business-lines-content-container {
    padding-bottom: 3.5714rem;
    width: 100%;
  }
}
