.employees-content-container {
    display: flex;
    flex-grow: 1;
    height: 100%;
}

@media only screen and (max-width: 425px) {
  .employees-content-container {
    padding-bottom: 3.5714rem;
  }
}