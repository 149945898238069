.role-details {
  gap: 0.5714rem;
}

.role-details .page-content {
  display: flex;
  flex-direction: column;
  gap: 1.8571rem;
}

.role-details .page-content .text-input.search-box {
  width: 19.7857rem;
}

.role-details .page-content .role-data {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.role-details .page-content .role-data-content {
  display: flex;
  gap: 1.7143rem;
}

.role-details .page-content .role-data .header {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .role-details .page-content .role-data-content {
    flex-direction: column;
  }
}

@media only screen and (max-width: 425px) {
  .role-details .page-content .text-input.search-box {
    width: 100%;
  }
}
