.popup-calibrate-evaluation .calibrate-evaluation {
    display: flex;
    flex-direction: column;
    gap: 1.1429rem;
}

.popup-calibrate-evaluation .calibrate-evaluation .employee-card {
    padding: 1rem;
}

.popup-calibrate-evaluation .calibrate-evaluation .employee-card .employee-label {
    color: var(--grey-palette-01);
}

.popup-calibrate-evaluation .calibrate-evaluation .employee-card .employee-value {
    font-weight: var(--font-weight-bold);
}

