.evaluation-matrices {
    gap: .5714rem;
}

.evaluation-matrices .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}

.evaluation-matrices .page-content .text-input.search-box {
    width: 19.7857rem;
}

/* .popup-container.system-popup.warning-popup.disable-role-popup .popup-buttons .button.primary {
    background: var(--status-red);
} */

@media only screen and (max-width: 425px) {
    .evaluation-matrices .page-content .text-input.search-box {
        width: 100%;
    }
  }
