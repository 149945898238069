.skills-cat-evaluations-card {
  display: flex;
  flex-direction: column;
  gap: 0.2857rem;
}

.skills-cat-evaluations-card .card-header {
  display: flex;
  justify-content: space-between;
}

.skills-cat-evaluations-card table .next-role-column,
.skills-cat-evaluations-card .mobile-table-row .next-role-column {
  color: var(--primary-color);
}

.skills-cat-evaluations-card tr .table-column-header {
  padding: 0 0.5714rem;
}

.skills-cat-evaluations-card tr .table-row-cell {
  padding: 0.5714rem 0.5714rem;
}

.skills-cat-evaluations-card tr .text-input {
  min-width: 1.7143rem;
}

.skills-cat-evaluations-card tr .text-input input {
  width: 1.7143rem;
  padding-left: 0;
}

.skills-cat-evaluations-card tr .text-input input::placeholder {
  font-size: 0.7143rem;
}
