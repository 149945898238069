.employee-career-ladder-tree {
    display: flex;
    flex-wrap: wrap;
}

.employee-career-ladder-tree .employe-role-node {
    display: flex;
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper {
    display: flex;
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .lines {
    width: .8571rem;
    display: flex;
    flex-direction: column;
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .lines .horizontal-line {
    border-bottom: 1px solid var(--secondary-color);
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .lines .first-line {
    width: 100%;
    min-height: 1.4286rem;
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .lines .second-line {
    width: 100%;
    height: 100%;
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .role-node-content-wrapper > div{
    min-width: 11.4286rem;
    max-width: 11.4286rem;
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .role-node-content-wrapper .role-node-content {
    background-color: var(--grey-palette-02);
    border-radius: .3571rem;
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .1429rem;
    border-radius: .3571rem;
    padding: .8571rem .5714rem .8571rem .8571rem;
    background-color: var(--secondary-color);
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-header-info .act-btns{
    display: flex;
}


.employee-career-ladder-tree .employe-role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-header-info div {
    color: #ffffff;
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-header-info svg path {
    fill: #ffffff;
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-additional-info {
    display: flex;
    flex-direction: column;
    gap: .7143rem;
    padding: .8571rem;
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-additional-info .role-node-additional-info-block {
    display: flex;
    flex-direction: column;
    gap: .1429rem;
}

.employee-career-ladder-tree .employe-role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-additional-info .role-node-additional-info-block .label {
    color: var(--grey-palette-01);
}