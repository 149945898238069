.career-ladder {
  gap: 2.8571rem;
}

.career-ladder .page-content {
  display: flex;
  flex-direction: column;
  gap: 2.1429rem;
}

.career-ladder .page-content .create-career-ladder {
  width: 2.1429rem;
  height: 2.1429rem;
}

.career-ladder .page-content .create-career-ladder svg {
  width: 1.1429rem;
  height: auto;
}

.career-ladder .page-content .create-career-ladder svg path {
  fill: #ffffff;
}
