.certifications-page .page-content {
  display: flex;
  flex-direction: column;
  gap: 0.8571rem;
}

.certifications-page .text-input.search-box {
  width: 19.7857rem;
}

.certifications-page table tbody svg path:last-child {
  fill: var(--secondary-color);
}

.certifications-page .mobile-table-row svg path:last-child {
  fill: var(--secondary-color);
}

.popup-container.system-popup.warning-popup.delete-certification-popup
  .popup-buttons
  .button.primary {
  background: var(--status-red);
}

@media only screen and (max-width: 425px) {
  .certifications-page .text-input.search-box {
    width: 100%;
  }
}
