.certificates-evaluation-card {
    height: auto;
    flex: 1;
}

.card-container.titled.certificates-evaluation-card {
    gap: .4286rem;
}

.certificates-evaluation-card table thead {
    height: 1.8571rem;
}

.certificates-evaluation-card table thead .table-column-header {
    font-size: .8571rem;
    color: var(--grey-palette-01);
}

.certificates-evaluation-card table tbody .table-cell {
    min-height: 2.5714rem;
    padding: .7143rem 0.857rem;
}

.certificates-evaluation-card table tbody .table-cell svg path:last-child, .certificates-evaluation-card .mobile-table-cell svg path:last-child {
    fill: var(--secondary-color);
}