.employees-page .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}

.employees-page .text-input.search-box {
    width: 19.7857rem;
}


.employees-page .page-content .table-container .employee-info {
    display: flex;
    flex-direction: column;
    gap: 0.0714rem;
  }
  
  .employees-page .page-content .employee-info .employee-id {
    color: var(--grey-palette-01);
  }

@media only screen and (max-width: 425px) {
    .employees-page .text-input.search-box  {
        width: 100%;
    }
  }
