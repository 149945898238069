.absences-page .page-content {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}

.absences-page .page-content .text-input.search-box {
    width: 19.7857rem;
}

.absences-page .page-content .table-container .employee-info {
    display: flex;
    flex-direction: column;
    gap: .0714rem;
    max-width: 10rem;
}

.absences-page .page-content .table-container .employee-info .employee-id {
    color: var(--grey-palette-01);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media only screen and (max-width: 425px) {
    .absences-page .page-content .text-input.search-box {
        width: 100%;
    }
}