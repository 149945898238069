.bonus-details-page .page-content {
  display: flex;
  flex-direction: column;
  gap: 1.4286rem;
}

.bonus-details-page .top-section {
  display: flex;
  flex-direction: row;
  gap: 1.4286rem;
}

.bonus-details-page .top-section .period-data {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.1429rem;
  height: auto;
}

.bonus-details-page .top-section .period-data .card-header {
  display: flex;
  justify-content: space-between;
}

.bonus-details-page .top-section .evaluation-status-card {
  max-width: 10rem;
}

.bonus-details-page .criterias-section {
  display: flex;
  flex-direction: row;
  gap: 1.4286rem;
}

.bonus-details-page .criterias-section .company-criteria,
.bonus-details-page .criterias-section .employee-criteria {
  display: flex;
  flex-direction: column;
  gap: 0.5714rem;
  flex: 1;
  height: auto;
}

.bonus-details-page .criterias-section .employee-criteria {
  display: flex;
}

.bonus-details-page .criterias-section .table-row {
  pointer-events: none;
}

.bonus-details-page .evaluation-observations {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

@media only screen and (max-width: 1024px) {
  .bonus-details-page .top-section {
    flex-direction: column;
  }

  .bonus-details-page .top-section .evaluation-status-card {
    max-width: 100%;
    min-height: 3.5714rem;
  }

  .bonus-details-page .criterias-section {
    flex-direction: column;
  }
}
