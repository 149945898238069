.popup-conclude-evaluation .conclude-evaluation {
    display: flex;
    flex-direction: column;
    gap: 1.1429rem;
}

.popup-conclude-evaluation .conclude-evaluation .employee-card {
    padding: 1rem;
}

.popup-conclude-evaluation .conclude-evaluation .employee-card .employee-label {
    color: var(--grey-palette-01);
}

.popup-conclude-evaluation .conclude-evaluation .employee-card .employee-value {
    font-weight: var(--font-weight-bold);
}

.popup-conclude-evaluation .popup-buttons .conclude-btn{
    background-color: var(--status-red);
}