.create-evaluation-matrix {
    gap: .5714rem;
}

.create-evaluation-matrix .page-content {
    display: flex;
    flex-direction: column;
    gap: 1.4286rem;
}

.create-evaluation-matrix .page-content .basic-info-card {
    display: flex;
    flex-direction: column;
    gap: .8571rem;
}

.create-evaluation-matrix .page-content .table-column-header {
    font-size: .8571rem;
}
