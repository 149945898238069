.period-details
  .page-layout-header
  .page-layout-header-action-buttons
  .icon-button.primary
  svg
  path:first-child {
  fill: none;
}

.period-details
  .page-layout-header
  .page-layout-header-action-buttons
  .contextual-menu-panel
  svg
  path {
  fill: var(--secondary-color);
}

.period-details .page-content {
  display: flex;
  flex-direction: column;
  gap: 1.8571rem;
}

.period-details .page-content .period-data {
  display: flex;
  flex-direction: column;
  gap: 1.1429rem;
}

.period-details .page-content .period-data .card-header-container .header {
  display: flex;
  justify-content: space-between;
}

.period-details
  .page-content
  .period-data
  .card-header-container
  .sub-header-period-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.period-details
  .page-content
  .period-data
  .card-header-container
  .sub-header-period-data
  .created-date {
  color: var(--grey-palette-01);
  font-weight: var(--font-weight-bold);
}

.period-details .page-content .period-data .period-data-action-buttons {
  display: flex;
  justify-content: flex-end;
}

.period-details .page-content .period-matrices {
  display: flex;
  flex-direction: column;
}

.period-details .page-content .period-matrices .text-input.search-box {
  width: 19.7857rem;
  margin-bottom: 0.8571rem;
}

.popup-container.system-popup.warning-popup.cancel-evaluation-period-popup
  .popup-buttons
  .button.primary {
  background: var(--status-red);
}

.popup-container.system-popup.warning-popup.activate-evaluation-period-popup
  .popup-buttons
  .button.primary {
  background: var(--status-blue);
}

@media only screen and (max-width: 425px) {
  .period-details .page-content .period-matrices .text-input.search-box {
    width: 100%;
  }
}
