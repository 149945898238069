.job-positions {
    flex: 1;
}

.job-positions .job-positions-header {
    display: flex;
    justify-content: space-between;
}

.popup-container.system-popup.warning-popup.disable-job-position-popup .popup-buttons .button.primary {
    background: var(--status-red);
}