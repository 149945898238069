.popup-suggest-next-role .next-role-criterias {
  display: flex;
  flex-direction: column;
  gap: 0.2857rem;
}

.popup-suggest-next-role .next-role-criterias .empty-criterias {
  color: var(--grey-palette-01);
}

.popup-suggest-next-role .next-role-criterias .criterias-loader {
  position: relative;
  height: 7.1429rem;
}

.popup-suggest-next-role .popup-buttons {
  justify-content: flex-end;
}

.popup-suggest-next-role .popup-buttons .button {
  flex: unset;
  min-width: 9.5rem;
}

@media only screen and (max-width: 768px) {
  .popup-suggest-next-role .popup-buttons .button {
    flex: 1;
    min-width: unset;
  }
}
