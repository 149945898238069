.table-row:nth-child(odd) {
  background-color: var(--grey-palette-03);
}


.table-row:hover {
  background-color: var(--grey-palette-02);
  background-color: #ececec;
}

.table-row.highlighted {
/*   border: 0.5px solid var(--primary-color); */
/* background-color: rgba(185,184,184,.5) */
  background-color: var(--status-green-transparency-3)
}
