.checklist-card {
    display: flex;
    flex-direction: column;
}

.checklist-card .card-header {
    display: flex;
    justify-content: space-between;
}

.checklist-card .add-checklist-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.popup-container.system-popup.warning-popup.delete-checklist-item-popup .popup-buttons .button.primary {
    background: var(--status-red);
}