.role-node {
    display: flex;
}
.role-node .role-node-wrapper {
    display: flex;
}

.role-node .role-node-wrapper .lines {
    width: .8571rem;
    display: flex;
    flex-direction: column;
}

.role-node .role-node-wrapper .lines .horizontal-line {
    border-bottom: 1px solid var(--secondary-color);
}

.role-node .role-node-wrapper .lines .vertical-line {
    border-left: 1px solid var(--secondary-color);
}

.role-node .role-node-wrapper .lines .first-line {
    width: 100%;
    min-height: 1.4286rem;
}

.role-node .role-node-wrapper .lines .second-line {
    width: 100%;
    height: 100%;
}

.role-node .role-node-wrapper .role-node-content-wrapper > div{
    min-width: 11.4286rem;
    max-width: 11.4286rem;
}

.role-node .role-node-wrapper .role-node-content-wrapper .role-node-content {
    background-color: var(--grey-palette-02);
    border-radius: .3571rem;
}

.role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .1429rem;
    border-radius: .3571rem;
    padding: .8571rem .5714rem .8571rem .8571rem;
    background-color: var(--secondary-color);
}

.role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-header-info div {
    color: #ffffff;
}

.role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-header-info svg path {
    fill: #ffffff;
}

.role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-additional-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .1429rem;
    padding: .8571rem;
}

.role-node .role-node-wrapper .role-node-content-wrapper .role-node-content .role-node-additional-info .job-grade {
    color: var(--grey-palette-01);
}

.role-node .role-node-wrapper .role-node-content-wrapper .role-node-content.next-role-root-node {
    pointer-events: none;
}

.role-node .role-node-wrapper .role-node-content-wrapper .role-node-content.next-role-root-node .role-node-header-info {
    background-color: var(--grey-palette-01);
}

.role-node .role-node-wrapper .role-node-content-wrapper .role-node-content.selection-enabled {
    cursor: pointer;
}

.role-node .role-node-wrapper .role-node-content-wrapper .role-node-content.selection-enabled:hover .role-node-header-info {
    background-color: var(--primary-color);
}

.role-node .role-node-wrapper .role-node-content-wrapper .role-node-content.selection-enabled.selected .role-node-header-info{
    background-color: var(--primary-color);
}

.role-node .role-node-wrapper .role-node-content-wrapper .add-node-icon {
    display: flex;
    justify-content: flex-end;
    padding: .1429rem 0 1rem 0;
}