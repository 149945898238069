.skills-category-card .card-header {
    display: flex;
    justify-content: space-between;
}

.skills-category-card .table-container table thead {
    height: 2rem;
}

.skills-category-card th:first-child {
    vertical-align: bottom;
}

.skills-category-card th:not(th:first-child) {
    height: 120px;
    /* white-space: now rap; */
    max-width: 58px;
}
  
.skills-category-card th:first-child > div {
    padding-bottom: .4286rem;
}
.skills-category-card th:not(th:first-child) > div {
    /* transform: translate(6px, 50px) rotate(270deg);
    width: 0; */
    transform: translate(-40px, 0px) rotate(270deg);
    width: 120px;
}

.skills-category-card tr .table-column-header {
    padding: 0 .5714rem;
}

.skills-category-card tr .table-row-cell {
    padding: .5714rem .5714rem;
}

.skills-category-card tr .text-input {
    min-width: 1.7143rem;
}

.skills-category-card tr .text-input input {
    width: 1.7143rem;
    padding-left: 0;
}

.skills-category-card tr .text-input input::placeholder {
    font-size: .7143rem;
}